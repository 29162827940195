<template>
  <div class="page-content">
    <div class="header">
      <h3>Gestion des visibilités</h3>
    </div>
    <div class="blockMenu">
      <div @click="clickMenu('visibilityRegie')">
        <router-link class="menu-item" to="/setting/visibility">
          Visibilités régie
        </router-link>
      </div>
      <div @click="clickMenu('visibilityPlanning')">
        <router-link
          class="menu-item"
          to="/setting/visibility/visibilityPlanning"
        >
          Visibilités planning
        </router-link>
      </div>
    </div>
    <hr class="lineMenu" />
    <div class="content">
      <router-view ref="sortUsersView" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'visibility',
  data() {
    return {}
  },
  methods: {
    ...mapActions([]),
    clickMenu(menu) {
      this.selectedMenu = menu
    }
  },
  computed: {
    ...mapGetters([])
  }
}
</script>

<style lang="scss" scoped>
.page-content {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    .color {
      color: #2dabe2;
    }
  }
  .blockMenu {
    display: flex;
    margin-top: 15px;
    .menu-item {
      text-decoration: none;
      color: #26292c;
      padding: 10px;
      &.router-link-exact-active {
        color: #2dabe2;
        border-bottom: 2px solid #2dabe2;
      }
    }
  }
  .lineMenu {
    margin-top: 8px;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 400px) {
    .blockMenu {
      .menu-item {
        padding: 0px;
      }
    }
  }
}
</style>
